/**
 * Service that provides links to other modules.
 */
export abstract class AbstractLinkProviderService {
  /**
   * Provides a link to the page of a platform supplier, if applicable.
   * @param supplierId The ID of the supplier.
   */
  abstract tryGetLinkToPlatformSupplier(supplierId: string): string | undefined;
}
